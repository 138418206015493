import React, { Suspense } from 'react';

import { SpinnerFullScreen } from '../../components/SpinnerFullScreen';

const EntriesWithProvider = React.lazy(
  () => import('./EntriesRouteWithProvider'),
);

export const EntriesRouteWithLazy: React.FC = () => {
  return (
    <Suspense fallback={<SpinnerFullScreen />}>
      <EntriesWithProvider />
    </Suspense>
  );
};
