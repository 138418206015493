import React, { Suspense } from 'react';

import { SpinnerFullScreen } from '../../components/SpinnerFullScreen';

const FormCreateEditRoute = React.lazy(() => import('./FormCreateEdit'));

export const FormCreateEditRouteWithLazy: React.FC = () => {
  return (
    <Suspense fallback={<SpinnerFullScreen />}>
      <FormCreateEditRoute />
    </Suspense>
  );
};
