import React, { Suspense } from 'react';

import { SpinnerFullScreen } from '../../components/SpinnerFullScreen';

const KanbanRouteWithProvider = React.lazy(
  () => import('./KanbanRouteWithProvider'),
);

export const KnabanRouteWithLazy: React.FC = () => {
  return (
    <Suspense fallback={<SpinnerFullScreen />}>
      <KanbanRouteWithProvider />
    </Suspense>
  );
};
