import { createSlice } from '@reduxjs/toolkit';

import { TaskAxios } from '../../axios/task/task.axios';
import { KanbanTask } from '../../model/kanban/KanbanTask';
import { PageDto } from '../../model/meta/PageDto';
import { PageResponseDto } from '../../model/meta/PageResponseDto';
import { ContactTask } from '../../model/task/ContactTask';
import { LeadTask } from '../../model/task/LeadTask';
import { TasksSettings } from '../../model/task/TasksSettings';
import { BulkDeleteTasksDto } from '../../model/task/dto/BulkDeleteTasksDto';
import { GenericGetTasksDto } from '../../model/task/dto/GenericGetTasksDto';
import { GetTasksSettingsDto } from '../../model/task/dto/GetTasksSettingsDto';
import { TasksSettingsPatchDto } from '../../model/task/dto/TasksSettingsPatchDto';
import { AtiraThunk } from '../AtiraThunk';

type GenericTask = LeadTask | ContactTask | KanbanTask;

interface TaskReducer {
  leadTasksLoading: boolean;
  leadTasks: LeadTask[];
  contactTasksLoading: boolean;
  contactTasks: ContactTask[];
  kanbanTasksLoading: boolean;
  kanbanTasks: KanbanTask[];
  //
  tasksSettings: TasksSettings | null;
  allTasksLoading: boolean;
  allTasks: GenericTask[];
  allTasksMeta: PageDto | null;
  tasksPage: number;
  tasksPageSize: number;
}

const initialState = Object.freeze<TaskReducer>({
  leadTasksLoading: false,
  leadTasks: [],
  contactTasksLoading: false,
  contactTasks: [],
  kanbanTasksLoading: false,
  kanbanTasks: [],
  tasksSettings: null,
  allTasksLoading: false,
  allTasks: [],
  allTasksMeta: null,
  tasksPage: 1,
  tasksPageSize: 10,
});

const getLeadTasks = AtiraThunk<
  PageResponseDto<LeadTask[]>,
  GenericGetTasksDto
>(`/task/lead`, (dto) => TaskAxios.getLeadTasks(dto));

const getContactTasks = AtiraThunk<
  PageResponseDto<ContactTask[]>,
  GenericGetTasksDto
>(`/task/contact`, (dto) => TaskAxios.getContactTasks(dto));

const getKanbanTasks = AtiraThunk<
  PageResponseDto<KanbanTask[]>,
  GenericGetTasksDto
>(`/task/kanban`, (dto) => TaskAxios.getKanbanTasks(dto));

const getTasksSettings = AtiraThunk<TasksSettings, GetTasksSettingsDto>(
  '/tasks/settings',
  (dto) => TaskAxios.getTasksSettings(dto),
);

const updateTasksSettings = AtiraThunk<void, TasksSettingsPatchDto>(
  '/tasks/settings/patch',
  (dto) => TaskAxios.updateTasksSettings(dto),
);

const getAllTasks = AtiraThunk<
  PageResponseDto<GenericTask[]>,
  GenericGetTasksDto
>(`/task/all`, (dto) => TaskAxios.getAllTasks(dto));

const bulkDeleteTasks = AtiraThunk<void, BulkDeleteTasksDto>(
  '/task/bulk/delete',
  (dto) => TaskAxios.bulkDeleteTasks(dto),
);

const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    setTasksTablePageSize: (state, action) => {
      state.tasksPageSize = action.payload;
    },

    setTasksTablePage: (state, action) => {
      state.tasksPage = action.payload;
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(getLeadTasks.pending, (state) => {
      state.leadTasksLoading = true;
    });
    addCase(getLeadTasks.fulfilled, (state, action) => {
      state.leadTasks = action.payload.data;
      state.leadTasksLoading = false;
    });
    addCase(getLeadTasks.rejected, (state) => {
      state.leadTasksLoading = false;
    });

    addCase(getContactTasks.pending, (state) => {
      state.contactTasksLoading = true;
    });
    addCase(getContactTasks.fulfilled, (state, action) => {
      state.contactTasks = action.payload.data;
      state.contactTasksLoading = false;
    });
    addCase(getContactTasks.rejected, (state) => {
      state.contactTasksLoading = false;
    });

    addCase(getKanbanTasks.pending, (state) => {
      state.kanbanTasksLoading = true;
    });
    addCase(getKanbanTasks.fulfilled, (state, action) => {
      state.kanbanTasks = action.payload.data;
      state.kanbanTasksLoading = false;
    });
    addCase(getKanbanTasks.rejected, (state) => {
      state.kanbanTasksLoading = false;
    });

    addCase(getTasksSettings.fulfilled, (state, action) => {
      state.tasksSettings = action.payload;
    });

    addCase(getAllTasks.pending, (state) => {
      state.allTasksLoading = true;
    });
    addCase(getAllTasks.fulfilled, (state, action) => {
      state.allTasks = action.payload.data;
      state.allTasksMeta = action.payload.meta;
      state.allTasksLoading = false;
    });
    addCase(getAllTasks.rejected, (state) => {
      state.allTasksLoading = false;
    });
  },
});

export const taskActions = {
  ...taskSlice.actions,
  getLeadTasks,
  getContactTasks,
  getTasksSettings,
  updateTasksSettings,
  getKanbanTasks,
  getAllTasks,
  bulkDeleteTasks,
};

export default taskSlice.reducer;
