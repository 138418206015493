import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, LinkProps } from 'react-router-dom';

import { AppLangs } from '../i18n';
import { getCurrentLang } from '../utils/getCurrentLang';

type MultiLangLinkProps = {} & LinkProps;

const currentLang = getCurrentLang();

export const MultiLangLink: React.FC<MultiLangLinkProps> = (props) => {
  const { i18n } = useTranslation();

  const to = props.to as string;
  const customTo = currentLang === AppLangs.EN ? to : `/${i18n.language}${to}`;

  return <Link {...props} to={customTo} />;
};
