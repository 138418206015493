import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons/faCircleExclamation';
import { Tooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../../../components/AtiraIcon';
import { Button } from '../../../../components/Button';
import { Flex } from '../../../../components/Flex';
import { SpinnerFullScreen } from '../../../../components/SpinnerFullScreen';
import { Text } from '../../../../components/Text';
import { Modal } from '../../../../components/modal/Modal';
import { permissionSliceSelectors } from '../../../../redux/permission/permission.selector';
import { permissionActions } from '../../../../redux/permission/permission.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { userSliceSelectors } from '../../../../redux/user/user.selector';
import { userActions } from '../../../../redux/user/user.slice';
import { AtiraToast } from '../../../../utils/AtiraToast';

const LoadingOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.white};
  opacity: 0.8;
  z-index: 1;
  display: flex;
`;

const AcceptButton = styled(Button)`
  background-color: ${({ theme }) => theme.main};
  color: ${({ theme }) => theme.white};
`;

const RejectButton = styled(Button)`
  background-color: ${({ theme }) => theme.red};
  color: ${({ theme }) => theme.white};
`;

export const InviteNeedActionModal: React.FC = () => {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  const currentInvite = useAppSelector(
    permissionSliceSelectors.selectCurrentInvite,
  );

  const user = useAppSelector(userSliceSelectors.selectLoggedInUser)!;

  const { t } = useTranslation(undefined, {
    keyPrefix: 'permissions.invite.pending-action.modal',
  });
  const dispatch = useAppDispatch();

  const canShowModal =
    currentInvite && currentInvite.inviteeEmail === user.email;

  const onRespondToInvite = async (accept: boolean) => {
    try {
      setLoading(true);
      await dispatch(
        permissionActions.respondToInvite({
          accept,
          userId: user._id,
          inviteCode: currentInvite?.inviteCode!,
        }),
      ).unwrap();
      dispatch(permissionActions.resetCurrentInvite({}));
      await dispatch(
        userActions.getLoggedInUser({ userId: user?._id }),
      ).unwrap();
      setOpen(false);

      if (accept) {
        AtiraToast.success(
          t('accept', {
            name: currentInvite?.inviter?.name || 'Atira Space user',
          }),
        );
      } else {
        AtiraToast.info(t('reject'));
      }

      setTimeout(() => {
        window.location.reload();
      }, 6000);
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setLoading(false);
    }
  };

  if (!canShowModal) {
    return null;
  }

  return (
    <Modal maskClosable={false} open={open} title="Pending Invite">
      <Flex flexDirection="column" gap="m" position="relative">
        <Text fontSize="xm" align="center">
          {t('title', {
            name: currentInvite.inviter?.name || 'Atira Space user',
          })}
        </Text>
        <Text color="darkerSub">{t('title-required')}</Text>
        <Text color="darkerSub">{t('description')}</Text>
        <Text color="red">{t('warning')}</Text>
        {loading ? (
          <LoadingOverlay>
            <SpinnerFullScreen />
          </LoadingOverlay>
        ) : null}
        <Flex gap="m" justifyContent="center" alignItems="center">
          <Flex>
            <Tooltip title={t('button.accept-description')}>
              <Flex cursor="pointer">
                <AtiraIcon icon={faCircleExclamation} color="gray" />
              </Flex>
            </Tooltip>
            <AcceptButton
              onClick={() => onRespondToInvite(true)}
              title={t('button.accept-title')}
              disabled={loading}
            />
          </Flex>

          <Flex>
            <Tooltip title={t('button.cancel-description')}>
              <Flex cursor="pointer">
                <AtiraIcon icon={faCircleExclamation} color="gray" />
              </Flex>
            </Tooltip>
            <RejectButton
              onClick={() => onRespondToInvite(false)}
              title={t('button.cancel-title')}
              disabled={loading}
            />
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
};
