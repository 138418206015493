import React from 'react';
import { Navigate } from 'react-router-dom';

import { useAppSelector } from '../redux/store';
import { userSliceSelectors } from '../redux/user/user.selector';

type AtiraRouteIndexProps = {
  component?: React.ReactNode;
};

export const AtiraRouteIndex: React.FC<AtiraRouteIndexProps> = ({
  component,
}) => {
  const loggedInUserId = useAppSelector(
    userSliceSelectors.selectLoggedInUserId,
  );

  if (loggedInUserId) {
    return <Navigate to={'/dashboard'} replace />;
  }

  return component;
};
