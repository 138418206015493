import { RouteObject, createBrowserRouter } from 'react-router-dom';

import { AppLangs } from '../../i18n';
import { makeMultiLangRouter } from '../../utils/MultiLangRouter';
import { getCurrentLang } from '../../utils/getCurrentLang';
import { AtiraRoute } from '../AtiraRoute';
import { AtiraRouteNotLoggedIn } from '../AtiraRouteNotLoggedIn';
import { AtiraRouteRedirct } from '../AtiraRouteRedirect';
import { Login } from '../loginSignup/Login';
import { Signup } from '../loginSignup/signup/Signup';
import { PrivateRouter } from './Private.router';
import { PublicRouter } from './Public.router';

const currentLang = getCurrentLang();

const router = createBrowserRouter([
  ...(currentLang === AppLangs.EN
    ? []
    : ([
        {
          path: '/',
          element: <AtiraRouteRedirct redirect={`/${currentLang}/`} />,
        },
      ] as RouteObject[])),
  ...makeMultiLangRouter(PublicRouter),
  ...PrivateRouter,
  // login and signup routes need to be here too for google auth to work for non-English languages
  {
    path: '/login',
    element: <AtiraRouteNotLoggedIn component={<Login />} />,
  },
  {
    path: '/signup',
    element: <AtiraRouteNotLoggedIn component={<Signup />} />,
  },
  { path: '*', element: <AtiraRoute redirect="/" /> },
] as RouteObject[]);

export default router;
