import React, { Suspense } from 'react';

import { SpinnerFullScreen } from '../../components/SpinnerFullScreen';

const TaskRoute = React.lazy(() => import('./TasksRoute'));

export const TaskRouteWithLazy: React.FC = () => {
  return (
    <Suspense fallback={<SpinnerFullScreen />}>
      <TaskRoute />
    </Suspense>
  );
};
