import React, { Suspense } from 'react';

import { SpinnerFullScreen } from '../../components/SpinnerFullScreen';

const UseCasesRoute = React.lazy(() => import('./UseCases'));

export const UseCasesRouteWithLazy: React.FC = () => {
  return (
    <Suspense fallback={<SpinnerFullScreen />}>
      <UseCasesRoute />
    </Suspense>
  );
};
