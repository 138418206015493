import React, { createContext, useContext, useState } from 'react';

import { PermissionInvite } from '../../model/permissions/PermisisonInvite';
import { User } from '../../model/user/User';

interface PermissionsContextType {
  currentMember: User | null;
  setCurrentMember: ATVoidFunction<User | null>;
  removeMemberModalVisible: boolean;
  setRemoveMemberModalVisible: ATVoidFunction<boolean>;
  inviteMemberModalVisible: boolean;
  setInviteMemberModalVisible: ATVoidFunction<boolean>;
  //
  currentInvite?: PermissionInvite;
  setCurrentInvite: ATVoidFunction<PermissionInvite | undefined>;
  cancelInviteModalVisible: boolean;
  setCancelInviteModalVisible: ATVoidFunction<boolean>;
  //
}

const PermissionsContext = createContext<PermissionsContextType | undefined>(
  undefined,
);

export const usePermissionsContext = () => {
  const context = useContext(PermissionsContext);

  if (!context) {
    throw new Error(
      'usePermissionsContext must be used within a PermissionsProvider',
    );
  }

  return context;
};

interface PermissionContextProviderProps {
  children: React.ReactNode;
}

export const PermissionContextProvider: React.FC<
  PermissionContextProviderProps
> = ({ children }) => {
  const [currentMember, setCurrentMember] = useState<User | null>(null);
  const [removeMemberModalVisible, setRemoveMemberModalVisible] =
    useState(false);
  const [inviteMemberModalVisible, setInviteMemberModalVisible] =
    useState(false);
  const [cancelInviteModalVisible, setCancelInviteModalVisible] =
    useState(false);
  const [currentInvite, setCurrentInvite] = useState<PermissionInvite>();

  return (
    <PermissionsContext.Provider
      value={{
        currentMember,
        setCurrentMember,
        removeMemberModalVisible,
        setRemoveMemberModalVisible,
        inviteMemberModalVisible,
        setInviteMemberModalVisible,
        cancelInviteModalVisible,
        setCancelInviteModalVisible,
        currentInvite,
        setCurrentInvite,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};
