import i18n, { AppLangs } from '../i18n';

export const getCurrentLang = () => {
  const possibleLangFromUrl = window.location.pathname.split(
    '/',
  )[1] as AppLangs;

  const currentLang = Object.values(AppLangs).includes(possibleLangFromUrl)
    ? possibleLangFromUrl
    : i18n.language;

  return currentLang;
};
