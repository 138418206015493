import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationAR from './locales/ar.json';
import translationEN from './locales/en.json';
import translationTR from './locales/tr.json';

export enum AppLangs {
  AR = 'ar',
  EN = 'en',
  TR = 'tr',
}

const resources: Record<string, Resource> = {
  en: { translation: translationEN },
  ar: { translation: translationAR },
  tr: { translation: translationTR },
};

const possibleLangFromUrl = window.location.pathname.split('/')[1] as AppLangs;
const currentLang = Object.values(AppLangs).includes(possibleLangFromUrl)
  ? possibleLangFromUrl
  : i18n.language;

const langToUse =
  currentLang ||
  ((window.localStorage.getItem('atiraspace_lang') || AppLangs.EN) as AppLangs);

window.localStorage.setItem('atiraspace_lang', langToUse);

i18n.use(initReactI18next).init({
  resources,
  lng: langToUse,
  fallbackLng: AppLangs.EN,
  interpolation: { escapeValue: false },
});

i18n.on('languageChanged', (lang) => {
  // We need to reload the page to apply the different font
  window.localStorage.setItem('atiraspace_lang', lang);
  window.location.reload();
});

export const replaceLangPathInUrl = (newLang: AppLangs) => {
  let newUrl: string;

  if (newLang === AppLangs.EN) {
    newUrl = window.location.pathname.replace(`/${currentLang}`, '');
  } else {
    newUrl = `/${newLang}${window.location.pathname.replace(`/${currentLang}`, '')}`;
  }

  window.history.replaceState({}, '', newUrl);
};

export default i18n;
