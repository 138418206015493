import { Navigate } from 'react-router-dom';

import { PermissionAction } from '../model/permissions/types/PermissionAction';
import { PermissionType } from '../model/permissions/types/PermissionType';
import { useAppSelector } from '../redux/store';
import { userSliceSelectors } from '../redux/user/user.selector';

interface AtiraRouteProps {
  component?: React.ReactNode;
  redirect?: string;
  withPermissions?: {
    type: PermissionType;
    action: PermissionAction;
  }[];
}

/** Makes sure only logged-in users can go to props.component */
export const AtiraRoute: React.FC<AtiraRouteProps> = ({
  component,
  redirect,
  withPermissions,
}) => {
  const loggedInUser = useAppSelector(userSliceSelectors.selectLoggedInUser);
  const isLoggedIn = Boolean(loggedInUser?._id);
  const permissions = loggedInUser?.permissions?.permissions;

  if (!isLoggedIn) {
    return <Navigate to={'/login'} replace />;
  }

  if (redirect) {
    return <Navigate to={redirect} replace />;
  }

  if (withPermissions?.length) {
    for (const onePermission of withPermissions) {
      if (permissions?.[onePermission.type]?.[onePermission.action] === true) {
        // The loop doesn't have to continue if the user has a permission from the available 3 tasks permissions
        // Think of it as a break statement, a hasOne() function
        return component;
      }
    }

    return <Navigate to={'/'} replace />;
  }

  return component;
};
