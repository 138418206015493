import React, { Suspense } from 'react';

import { SpinnerFullScreen } from '../../components/SpinnerFullScreen';

const AdminRoute = React.lazy(() => import('./AdminRoute'));

export const AdminRouteWithLazy: React.FC = () => {
  return (
    <Suspense fallback={<SpinnerFullScreen />}>
      <AdminRoute />
    </Suspense>
  );
};
