import React, { Suspense } from 'react';

import { SpinnerFullScreen } from '../../components/SpinnerFullScreen';

const PricingRoute = React.lazy(() => import('./Pricing'));

export const PricingRouteWithLazy: React.FC = () => {
  return (
    <Suspense fallback={<SpinnerFullScreen />}>
      <PricingRoute />
    </Suspense>
  );
};
