import { RouteObject } from 'react-router-dom';

import { AtiraRouteIndex } from '../AtiraRouteIndex';
import { AtiraRouteNotLoggedIn } from '../AtiraRouteNotLoggedIn';
import { PublicLayout } from '../PublicLayout';
import { FeaturesRouteWithLazy } from '../features/FeaturesRouteWithLazy';
import { LandingPageRouteWithLazy } from '../landing-page/LandingPageRouteWithLazy';
import { Login } from '../loginSignup/Login';
import { ForgotPassword } from '../loginSignup/forgot-password/ForgotPassword';
import { Signup } from '../loginSignup/signup/Signup';
import { InviteLandingPage } from '../permissions/invite/InvitesLandingPage';
import { PricingRouteWithLazy } from '../pricing/PricingRouteWithLazy';
import { UseCasesRouteWithLazy } from '../use-cases/UseCasesRouteWithLazy';

export const PublicRouter: RouteObject[] = [
  {
    element: <PublicLayout />,
    errorElement: (
      <div>Something went wrong... Please try refreshing the page</div>
    ),
    children: [
      {
        path: '/',
        element: <AtiraRouteIndex component={<LandingPageRouteWithLazy />} />,
      },
      {
        path: '/features',
        element: (
          <AtiraRouteNotLoggedIn component={<FeaturesRouteWithLazy />} />
        ),
      },
      {
        path: '/pricing',
        element: <AtiraRouteNotLoggedIn component={<PricingRouteWithLazy />} />,
      },
      {
        path: '/use-cases',
        element: (
          <AtiraRouteNotLoggedIn component={<UseCasesRouteWithLazy />} />
        ),
      },
    ],
  },
  {
    path: '/login',
    element: <AtiraRouteNotLoggedIn component={<Login />} />,
  },
  {
    path: '/signup',
    element: <AtiraRouteNotLoggedIn component={<Signup />} />,
  },
  {
    path: '/forgot-password',
    element: <AtiraRouteNotLoggedIn component={<ForgotPassword />} />,
  },
  {
    path: '/permissions/invite',
    element: <InviteLandingPage />,
  },
];
