import { PermissionInvite } from '../../model/permissions/PermisisonInvite';
import { GetPermissionInviteByIdDto } from '../../model/permissions/dto/GetPermissionInviteByIdDto';
import { PermissionCancelInviteDto } from '../../model/permissions/dto/PermissionCancelInviteDto';
import { PermissionLeaveOrganizationDto } from '../../model/permissions/dto/PermissionLeaveOrganizationDto';
import { PermissionRemoveMemberByIdDto } from '../../model/permissions/dto/PermissionRemoveMemberByIdDto';
import { PermissionRespondToInviteDto } from '../../model/permissions/dto/PermissionRespondToInviteDto';
import { PermissionUpdateMemberPermissionsDto } from '../../model/permissions/dto/PermissionUpdateMemberPermissionsDto';
import { PermissionsInviteMembersDto } from '../../model/permissions/dto/PermissionsInviteMembersDto';
import { GenericWithUserId } from '../../model/shared/dto/GenericWithUserId';
import { User } from '../../model/user/User';
import { AtiraApi } from '../AxiosClient';

export class PermissionAxios {
  static inviteMembers(dto: PermissionsInviteMembersDto) {
    return AtiraApi.post<void>(`/permission/invite-members`, dto).then(
      ({ data }) => data,
    );
  }

  static getInvitedMembers(dto: GenericWithUserId) {
    return AtiraApi.post<PermissionInvite[]>(
      `/permission/invited-members`,
      dto,
    ).then(({ data }) => data);
  }

  static getInviteByCode(dto: GetPermissionInviteByIdDto) {
    return AtiraApi.post<PermissionInvite>(
      `/permission/invite/${dto.inviteCode}`,
      dto,
    ).then(({ data }) => data);
  }

  static respondToInvite(dto: PermissionRespondToInviteDto) {
    return AtiraApi.post<void>(
      `/permission/invite/${dto.inviteCode}/respond`,
      dto,
    ).then(({ data }) => data);
  }

  static cancelPermissionInvite(dto: PermissionCancelInviteDto) {
    return AtiraApi.post<void>(
      `/permission/invite/${dto.inviteId}/cancel`,
      dto,
    ).then(({ data }) => data);
  }

  static getMembers(dto: GenericWithUserId) {
    return AtiraApi.post<User[]>(`/permission/members`, dto).then(
      ({ data }) => data,
    );
  }

  static removeMember(dto: PermissionRemoveMemberByIdDto) {
    return AtiraApi.post<void>(
      `/permission/members/${dto.memberId}/remove`,
      dto,
    ).then(({ data }) => data);
  }

  static leaveOrganization(dto: PermissionLeaveOrganizationDto) {
    return AtiraApi.post<void>(
      `/permission/organization/${dto.organizationId}/leave`,
      dto,
    ).then(({ data }) => data);
  }

  static updateMemberPermissions(dto: PermissionUpdateMemberPermissionsDto) {
    return AtiraApi.patch<void>(
      `/permission/member/${dto.memberId}/update-permissions`,
      dto,
    ).then(({ data }) => data);
  }
}
