import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons/faCircleInfo';
import { Popover } from 'antd';
import React from 'react';
import styled from 'styled-components';

import i18n, { AppLangs } from '../i18n';
import { Rounded } from '../theme/Rounded';
import { Spacing } from '../theme/Spacing';
import { Button } from './Button';
import { Flex } from './Flex';
import { Text } from './Text';

const Container = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.white};
  margin-bottom: ${Spacing.m};
  padding: ${Spacing.m};
  width: 100%;
  height: 3.3rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  position: sticky;
  top: 0;
  z-index: 100;
`;

const StyledButton = styled(Button)`
  font-size: 1.1rem;
  background: ${(props) => props.theme.lightSub};
  padding: ${Spacing.s};
  border-radius: ${Rounded.lg};
  transition: border-bottom 0.3s;
  border: 1px solid ${(props) => props.theme.transparent};
  transition: all 0.3s;
  &:hover {
    background-color: ${(props) => props.theme.sub};
    border: 1px solid ${(props) => props.theme.darkSub};
  }
`;

const PopoverButton = styled(Button)`
  background-color: ${({ theme }) => theme.transparent};
  color: ${({ theme }) => theme.main};
  margin: 0;
  padding: 0;
`;

const StyledUl = styled.ul`
  list-style-position: inside;
  display: flex;
  flex-direction: column;
  gap: ${Spacing.s};
  max-height: 20rem;
  max-width: 17rem;
  overflow-wrap: break-word;
  overflow-y: scroll;

  li::marker {
    color: ${({ theme }) => theme.main};
  }
`;

interface SubHeaderProps {
  title: string;
  icon?: IconProp;
  buttonTitle?: string;
  onClick?: VoidFunction;
  enabled?: boolean;
  showButton?: boolean;
  notes?: string[];
}

export const SubHeader: React.FC<SubHeaderProps> = ({
  title,
  icon,
  onClick,
  buttonTitle,
  enabled = true,
  notes = [],
  showButton = true,
}) => {
  return (
    <Container>
      <Flex gap="s" alignItems="center">
        <Text fontWeight="bold" fontSize="l">
          {title}
        </Text>

        {notes.length ? (
          <Popover
            content={
              <StyledUl>
                {notes.map((x, i) => (
                  <li key={`${x}-${i}`}>{x}</li>
                ))}
              </StyledUl>
            }
            placement={
              i18n.language === AppLangs.AR ? 'bottomLeft' : 'bottomRight'
            }
            trigger={'hover'}
          >
            <PopoverButton icon={faCircleInfo} iconWidth="xl" />
          </Popover>
        ) : null}
      </Flex>

      {showButton ? (
        <StyledButton
          onClick={onClick}
          icon={icon}
          disabled={!enabled}
          color={enabled ? 'black' : 'gray'}
        >
          {buttonTitle}
        </StyledButton>
      ) : null}
    </Container>
  );
};
