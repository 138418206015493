import React, { Suspense } from 'react';

import { SpinnerFullScreen } from '../../components/SpinnerFullScreen';

const AdminSearchRoute = React.lazy(() => import('./AdminSearchUsers'));

export const AdminSearchUsersRouteWithLazy: React.FC = () => {
  return (
    <Suspense fallback={<SpinnerFullScreen />}>
      <AdminSearchRoute />
    </Suspense>
  );
};
