import { KanbanTask } from '../../model/kanban/KanbanTask';
import { PageResponseDto } from '../../model/meta/PageResponseDto';
import { ContactTask } from '../../model/task/ContactTask';
import { LeadTask } from '../../model/task/LeadTask';
import { TasksSettings } from '../../model/task/TasksSettings';
import { BulkDeleteTasksDto } from '../../model/task/dto/BulkDeleteTasksDto';
import { GenericGetTasksDto } from '../../model/task/dto/GenericGetTasksDto';
import { GetTasksSettingsDto } from '../../model/task/dto/GetTasksSettingsDto';
import { TasksSettingsPatchDto } from '../../model/task/dto/TasksSettingsPatchDto';
import { AtiraApi } from '../AxiosClient';

export class TaskAxios {
  static getLeadTasks(dto: GenericGetTasksDto) {
    return AtiraApi.post<PageResponseDto<LeadTask[]>>(
      `/task/lead/all`,
      dto,
    ).then(({ data }) => data);
  }

  static getContactTasks(dto: GenericGetTasksDto) {
    return AtiraApi.post<PageResponseDto<ContactTask[]>>(
      `/task/contact/all`,
      dto,
    ).then(({ data }) => data);
  }

  static getKanbanTasks(dto: GenericGetTasksDto) {
    return AtiraApi.post<PageResponseDto<KanbanTask[]>>(
      `/task/kanban/all`,
      dto,
    ).then(({ data }) => data);
  }

  static getTasksSettings(dto: GetTasksSettingsDto) {
    return AtiraApi.post<TasksSettings>(`/task/settings`, dto).then(
      ({ data }) => data,
    );
  }

  static updateTasksSettings(dto: TasksSettingsPatchDto) {
    return AtiraApi.patch<void>(`/task/settings`, dto).then(({ data }) => data);
  }

  static getAllTasks(dto: GenericGetTasksDto) {
    return AtiraApi.post<
      PageResponseDto<(LeadTask | ContactTask | KanbanTask)[]>
    >(`/task/all`, dto).then(({ data }) => data);
  }

  static bulkDeleteTasks(dto: BulkDeleteTasksDto) {
    return AtiraApi.post<void>('/task/delete/bulk', dto).then(
      ({ data }) => data,
    );
  }
}
