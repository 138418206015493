import { EntriesTableSettings } from '../../model/entries-table-settings/EntriesTableSettings';
import { GetUserEntriesTableSettingsDto } from '../../model/entries-table-settings/dto/GetUserEntriesTableSettingsDto';
import { UpdateUserEntriesTableSettingsDto } from '../../model/entries-table-settings/dto/UpdateUserEntriesTableSettingsDto';
import { Entry } from '../../model/entry/Entry';
import { ConvertEntryBulkDto } from '../../model/entry/dto/ConvertEntryBulkDto';
import { CreateLeadTaskDto } from '../../model/entry/dto/CreateLeadTaskDto';
import { CreateEntryDto } from '../../model/entry/dto/CreateNewEntryDto';
import { CreateContactTaskDto } from '../../model/entry/dto/CreateTaskDto';
import { DeleteContactTaskDto } from '../../model/entry/dto/DeleteContactTaskDto';
import { DeleteEntryBulkDto } from '../../model/entry/dto/DeleteEntryBulkDto';
import { DeleteEntryDto } from '../../model/entry/dto/DeleteEntryDto';
import { DeleteLeadTaskDto } from '../../model/entry/dto/DeleteLeadTaskDto';
import { ExportEntriesDto } from '../../model/entry/dto/ExportEntriesDto';
import { GetEntriesByFormIdDto } from '../../model/entry/dto/GetEntriesByFormIdDto';
import { GetEntryByIdDto } from '../../model/entry/dto/GetEntryByIdDto';
import { GetEntryLegibleForKanbanCardDto } from '../../model/entry/dto/GetEntryLegibleForKanbanCardDto';
import { GetLatestEntriesDto } from '../../model/entry/dto/GetLatestEntriesDto';
import { ImportEntriesDto } from '../../model/entry/dto/ImportEntriesDto';
import { UpdateContactTaskDto } from '../../model/entry/dto/UpdateContactTaskDto';
import { UpdateEntryDto } from '../../model/entry/dto/UpdateEntryDto';
import { PageResponseDto } from '../../model/meta/PageResponseDto';
import { IncrementLeadTaskAttempDto } from '../../model/task/dto/IncrementLeadTaskAttemptDto';
import { UpdateLeadTaskDto } from '../../model/task/dto/UpdateLeadTaskDto';
import { AtiraApi } from '../AxiosClient';

export class EntryAxios {
  static getEntryById(dto: GetEntryByIdDto) {
    return AtiraApi.post<Entry>(`/entry/${dto.entryId}`, dto).then(
      ({ data }) => data,
    );
  }

  static deleteEntry(dto: DeleteEntryDto) {
    return AtiraApi.post<void>(`/entry/${dto.entryId}/delete`, dto).then(
      ({ data }) => data,
    );
  }

  static deleteEntryBulk(dto: DeleteEntryBulkDto) {
    return AtiraApi.post<void>('/entry/delete/bulk', dto).then(
      ({ data }) => data,
    );
  }

  static updateEntryById(dto: UpdateEntryDto) {
    return AtiraApi.patch<void>(`/entry/${dto.entryId}`, dto).then(
      ({ data }) => data,
    );
  }

  static getEntriesByFormId(dto: GetEntriesByFormIdDto) {
    return AtiraApi.post<Entry[]>(`/entry/form/${dto.formId}`, dto).then(
      ({ data }) => data,
    );
  }

  static createNewEntry(dto: CreateEntryDto) {
    return AtiraApi.post<void>('/form/submit', dto).then(({ data }) => data);
  }

  static getLatestEntries(dto: GetLatestEntriesDto) {
    return AtiraApi.post<PageResponseDto<Entry[]>>(`/entry/latest`, dto).then(
      ({ data }) => data,
    );
  }

  static convertEntryBulk(dto: ConvertEntryBulkDto) {
    return AtiraApi.patch<void>('/entry/convert/bulk', dto).then(
      ({ data }) => data,
    );
  }

  static getUserTableSettings(dto: GetUserEntriesTableSettingsDto) {
    return AtiraApi.post<EntriesTableSettings>(
      '/entry/table-settings',
      dto,
    ).then(({ data }) => data);
  }

  static updateUserTableSettings(dto: UpdateUserEntriesTableSettingsDto) {
    return AtiraApi.patch<void>(
      `/entry/table-settings/${dto.tableId}`,
      dto,
    ).then(({ data }) => data);
  }

  static getEntriesLegibleForKanbanCard(dto: GetEntryLegibleForKanbanCardDto) {
    return AtiraApi.post<Entry[]>(`/entry/search/kanban-legible`, dto).then(
      ({ data }) => data,
    );
  }

  static createContactTask(dto: CreateContactTaskDto) {
    return AtiraApi.post<void>('/task/contact/create', dto).then(
      ({ data }) => data,
    );
  }
  static updateContactTask(dto: UpdateContactTaskDto) {
    const formData = new FormData();

    Object.entries(dto).forEach(([key, value]) => {
      formData.append(key, value);
    });

    return AtiraApi.patch<void>(
      `/task/contact/${dto.taskId}/update`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    ).then(({ data }) => data);
  }

  static async incrementLeadTaskAttempt(dto: IncrementLeadTaskAttempDto) {
    return AtiraApi.post<void>(
      `/task/lead/${dto.taskId}/increment-contact`,
      dto,
    ).then(({ data }) => data);
  }

  static createLeadTask(dto: CreateLeadTaskDto) {
    return AtiraApi.post<void>('/task/lead/create', dto).then(
      ({ data }) => data,
    );
  }

  static updateLeadTask(dto: UpdateLeadTaskDto) {
    return AtiraApi.patch<void>(`/task/lead/${dto.taskId}`, dto).then(
      ({ data }) => data,
    );
  }

  static deleteLeadTask(dto: DeleteLeadTaskDto) {
    return AtiraApi.post<void>(`/task/lead/${dto.taskId}/delete`, dto).then(
      ({ data }) => data,
    );
  }

  static deleteContactTask(dto: DeleteContactTaskDto) {
    return AtiraApi.post<void>(`/task/contact/${dto.taskId}/delete`, dto).then(
      ({ data }) => data,
    );
  }

  static exportEntries(dto: ExportEntriesDto) {
    return AtiraApi.post<string>('/entry/data/export', dto).then(
      ({ data }) => data,
    );
  }

  static importEntries(dto: ImportEntriesDto) {
    const formData = new FormData();

    formData.set('userId', dto.userId);
    formData.set('file', dto.file as any);

    return AtiraApi.post<void>('/entry/data/import', dto, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(({ data }) => data);
  }
}
