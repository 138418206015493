import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';
import merge from 'lodash/merge';
import React, {
  Fragment,
  InputHTMLAttributes,
  forwardRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useTheme } from '../ThemeContext';
import i18n, { AppLangs } from '../i18n';
import { InputTypes } from '../model/form/types/InputTypes.enum';
import { Rounded } from '../theme/Rounded';
import { Spacing } from '../theme/Spacing';
import { AtiraDatePicker } from './AtiraDatePicker';
import { AtiraIcon } from './AtiraIcon';
import { Button } from './Button';
import { Flex } from './Flex';
import { Text } from './Text';
import {
  LabelDirection,
  TitleAlignment,
} from './shared/types/SharedLabelProps';

const ShowHidePasswordButton = styled(Button)`
  position: absolute;
  top: 0.75rem;
  right: ${i18n.language === AppLangs.AR ? 'auto' : Spacing.m};
  left: ${i18n.language === AppLangs.AR ? Spacing.m : 'auto'};
  margin: 0;
  padding: 0;
  color: ${(props) => props.theme.main};
`;

const StyledInput = styled.input`
  width: 100%;
  transition: box-shadow 0.2s;
  outline: none !important;
  border-radius: ${Rounded.md};
  height: 2.3rem;
  font-family: inherit;
  direction: ${({ type }) =>
    i18n.language === AppLangs.AR &&
    (type === InputTypes.NUMBER.toLowerCase() ||
      type === InputTypes.EMAIL.toLowerCase())
      ? 'ltr'
      : 'auto'};
  &::placeholder {
    text-align: ${({ type }) =>
      i18n.language === AppLangs.AR &&
      (type === InputTypes.NUMBER.toLowerCase() ||
        type === InputTypes.EMAIL.toLowerCase())
        ? 'end'
        : 'auto'};
  }
`;

const StyledInputWrapper = styled(Flex)`
  gap: 0.2rem;
  align-items: center;
  border-radius: ${Rounded.md};
`;

enum PasswordInputTypes {
  TEXT = 'TEXT',
  PASSWORD = 'PASSWORD',
}

type RegularInputProps<T> = InputProps & {
  ref: React.Ref<T>;
  inputType?: React.HTMLInputTypeAttribute;
  togglePasswordInputType: () => void;
};

const RegularInputComponent = forwardRef<
  HTMLInputElement,
  RegularInputProps<HTMLInputElement>
>(({ inputType, errorMessage, togglePasswordInputType, ...props }, ref) => {
  const { theme } = useTheme();
  const hasError = (errorMessage?.length || 0) > 0 && props.valid === false;

  return (
    <Flex flexDirection="column" position="relative" gap="s" flex={2}>
      {props.icon ? (
        <StyledInputWrapper
          opacity={props.disabled ? '0.2' : '1'}
          border={
            props.valid === false
              ? '2px solid red'
              : `1px solid ${theme.lightgray}`
          }
          borderRadius={props.borderRadius || 'md'}
          style={{ padding: `0 ${Spacing.s}` }}
        >
          <StyledInput
            ref={ref}
            type={inputType}
            style={merge(props.style, {
              height: props.height,
              border: '0',
              borderRadius: props.borderRadius,
              placeholder: props.placeholder,
              margin: props.margin,
            })}
            {...props}
            value={props.value as any}
          />

          <AtiraIcon icon={props.icon} size={props.iconSize || 'lg'} />
        </StyledInputWrapper>
      ) : (
        <Fragment>
          <StyledInput
            ref={ref}
            type={inputType}
            style={merge(
              props.style,
              props.disabled ? { opacity: '0.2' } : {},
              hasError
                ? { border: '2px solid red', boxShadow: 'unset' }
                : { border: props.border || `1px solid ${theme.lightgray}` },
              {
                width: '100%',
                height: props.height,
                borderRadius: props.borderRadius,
                placeholder: props.placeholder,
                padding: props.padding || `${Spacing.o} ${Spacing.s}`,
                margin: props.margin,
              },
            )}
            {...props}
            value={props.value as any}
          />

          {inputType === PasswordInputTypes.PASSWORD.toLowerCase() ? (
            <ShowHidePasswordButton
              onClick={togglePasswordInputType}
              icon={
                inputType === PasswordInputTypes.PASSWORD.toLowerCase()
                  ? faEye
                  : faEyeSlash
              }
              backgroundColor="transparent"
            />
          ) : null}
        </Fragment>
      )}
    </Flex>
  );
});

const RegularDateInputComponent: React.FC<
  Omit<RegularInputProps<HTMLDataElement>, 'togglePasswordInputType'>
> = (props) => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column">
      <AtiraDatePicker
        value={(props.value || null) as any}
        onChange={(date) => props.onChangeDate?.(date as any)}
        allowClear={props.required === false}
        required={props.required}
        placeholder={props.placeholder || t('common.select_date')}
        disabled={props.disabled}
      />
      {props.errorMessage && props.valid === false ? (
        <Text color="red" fontSize="s">
          {props.errorMessage}
        </Text>
      ) : null}
    </Flex>
  );
};

interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value'> {
  title?: string;
  id?: string;
  valid?: boolean;
  width?: string;
  height?: string;
  border?: string;
  borderRadius?: keyof typeof Rounded;
  placeholder?: string;
  padding?: string;
  margin?: string;
  icon?: IconProp;
  errorMessage?: string;
  color?: string;
  titleAlign?: keyof typeof TitleAlignment;
  labelDirection?: keyof typeof LabelDirection;
  iconSize?: SizeProp;
  onChangeDate?: (d: Date) => void;
  value?: string | Date | number;
}

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { type, errorMessage, ...restProps } = props;
  const { theme } = useTheme();
  const hasError = (errorMessage?.length || 0) > 0 && props.valid === false;

  const [inputType, setInputType] = useState(type);

  const togglePasswordInputType = () => {
    setInputType(
      inputType === PasswordInputTypes.PASSWORD.toLowerCase()
        ? PasswordInputTypes.TEXT.toLowerCase()
        : PasswordInputTypes.PASSWORD.toLowerCase(),
    );
  };

  return (
    <Flex flexDirection="column" gap="s" width={props.width || '100%'}>
      <Flex
        width={'100%'}
        gap="s"
        flexDirection={
          props.labelDirection === LabelDirection.HORIZONTAL ? 'row' : 'column'
        }
      >
        {props.title ? (
          <label
            htmlFor={props.id}
            style={{
              color: theme.black,
              cursor: 'pointer',
              textAlign: props.titleAlign,
              width: 'fit-content',
              alignSelf: props.titleAlign || 'flex-start',
              flex: 1,
              margin:
                props.labelDirection === LabelDirection.HORIZONTAL
                  ? 'auto'
                  : '0',
            }}
          >
            {props.title}
            {props.required ? (
              <span style={{ color: theme.red }}> *</span>
            ) : null}
          </label>
        ) : null}

        {inputType === InputTypes.DATE.toLowerCase() ? (
          <RegularDateInputComponent ref={ref} {...props} />
        ) : (
          <RegularInputComponent
            {...restProps}
            inputType={inputType}
            ref={ref}
            togglePasswordInputType={togglePasswordInputType}
            errorMessage={errorMessage}
          />
        )}

        {hasError ? (
          <Text color="red" fontSize="s">
            {errorMessage}
          </Text>
        ) : null}
      </Flex>
    </Flex>
  );
});
