import React, { Suspense } from 'react';

import { SpinnerFullScreen } from '../../components/SpinnerFullScreen';

const UserForms = React.lazy(() => import('./UserForms'));

export const UserFormsRouteWithLazy: React.FC = () => {
  return (
    <Suspense fallback={<SpinnerFullScreen />}>
      <UserForms />
    </Suspense>
  );
};
