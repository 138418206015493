import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons/faCircleExclamation';
import { faX } from '@fortawesome/free-solid-svg-icons/faX';
import { Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../../../components/AtiraIcon';
import { Button } from '../../../../components/Button';
import { Flex } from '../../../../components/Flex';
import { PermissionInvite } from '../../../../model/permissions/PermisisonInvite';
import { Spacing } from '../../../../theme/Spacing';
import { usePermissionsContext } from '../../permissions-context';

const StyledButton = styled(Button)`
  margin: 0;
  padding: ${Spacing.s};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${Spacing.s};
  color: ${({ theme }) => theme.white};
`;

type PendingMemberPENDINGProps = {
  invite: PermissionInvite;
};

export const PendingMemberPENDING: React.FC<PendingMemberPENDINGProps> = ({
  invite,
}) => {
  const { t } = useTranslation();
  const { setCurrentInvite, setCancelInviteModalVisible } =
    usePermissionsContext();

  const onCancel = () => {
    setCurrentInvite(invite);
    setCancelInviteModalVisible(true);
  };

  return (
    <Flex alignItems="center" gap="l">
      <Flex alignItems="flex-start" gap="s">
        <StyledButton backgroundColor="red" onClick={onCancel}>
          <AtiraIcon icon={faX} />

          {t('common.cancel')}
        </StyledButton>
        <Tooltip title={t('permissions.invite.member.status-pending')}>
          <Flex cursor="pointer">
            <AtiraIcon icon={faCircleExclamation} color="gray" />
          </Flex>
        </Tooltip>
      </Flex>
    </Flex>
  );
};
