import { RouteObject } from 'react-router-dom';

import { AppLangs } from '../i18n';
import { getCurrentLang } from './getCurrentLang';

const currentLang = getCurrentLang();

export function makeMultiLangRouter(router: RouteObject[]) {
  return router.map((route) => {
    if (route.path) {
      return {
        ...route,
        path: `${currentLang === AppLangs.EN ? '' : `/${currentLang}`}${route.path}`,
      };
    }

    if (route.children) {
      return {
        ...route,
        children: route.children.map((child) => ({
          ...child,
          path: `${currentLang === AppLangs.EN ? '' : `/${currentLang}`}${child.path}`,
        })),
      };
    }

    return route;
  });
}
