import React, { Suspense } from 'react';

import { SpinnerFullScreen } from '../../components/SpinnerFullScreen';

const Dashboard = React.lazy(() => import('./Dashboard'));

export const DashboardRouteWithLazy: React.FC = () => {
  return (
    <Suspense fallback={<SpinnerFullScreen />}>
      <Dashboard />
    </Suspense>
  );
};
