import React, { Suspense } from 'react';

import { SpinnerFullScreen } from '../../components/SpinnerFullScreen';

const FeaturesRoute = React.lazy(() => import('./Features'));

export const FeaturesRouteWithLazy: React.FC = () => {
  return (
    <Suspense fallback={<SpinnerFullScreen />}>
      <FeaturesRoute />
    </Suspense>
  );
};
