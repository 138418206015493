import type { CollapseProps } from 'antd';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useTheme } from '../../../ThemeContext';
import { AtiraCollapse } from '../../../components/AtiraCollapse';
import { AtiraEmpty } from '../../../components/AtiraEmpty';
import { Flex } from '../../../components/Flex';
import { WarningModal } from '../../../components/WarningModal';
import { permissionSliceSelectors } from '../../../redux/permission/permission.selector';
import { permissionActions } from '../../../redux/permission/permission.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Rounded } from '../../../theme/Rounded';
import { Shadow } from '../../../theme/Shadow';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';
import { MemberPermissions } from '../components/MemberPermissions';
import { MemberRow } from '../components/MemberRow';
import { usePermissionsContext } from '../permissions-context';

const Container = styled(Flex)`
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${Shadow.SM};
  border-radius: ${Rounded.md};
  padding: ${Spacing.s};
  flex-direction: column;
`;

const StyledCollapseWrapper = styled.div`
  .ant-collapse-header {
    padding: 0 !important;
  }

  .ant-collapse-expand-icon {
    padding-inline-end: ${Spacing.s} !important;
    padding-inline-start: 0 !important;
  }
`;

export const PermissionsMembersTab: React.FC = () => {
  const [removeMemberLoading, setRemoveMemberLoading] = useState(false);

  const { theme } = useTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    setRemoveMemberModalVisible,
    removeMemberModalVisible,
    currentMember,
  } = usePermissionsContext();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const members = useAppSelector(permissionSliceSelectors.selectMembers);

  const onRemoveMember = async () => {
    try {
      setRemoveMemberLoading(true);
      await dispatch(
        permissionActions.removeMember({
          memberId: currentMember?._id!,
          userId,
        }),
      ).unwrap();
      await dispatch(permissionActions.getMembers({ userId })).unwrap();
      setRemoveMemberModalVisible(false);
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setRemoveMemberLoading(false);
    }
  };

  const items = useMemo((): CollapseProps['items'] => {
    const panelStyle: React.CSSProperties = {
      borderBottom: '1px solid black',
      backgroundColor: theme.sub,
      border: '0',
    };

    return members.map((member) => ({
      key: `${member?._id}`,
      label: <MemberRow member={member} key={member._id} />,
      children: <MemberPermissions member={member} key={member._id} />,
      style: panelStyle,
    }));
  }, [members, theme.sub]);

  return (
    <Container>
      {items?.length ? (
        <StyledCollapseWrapper>
          <AtiraCollapse
            collapsible="icon"
            items={items}
            expandIconPosition="end"
          />
        </StyledCollapseWrapper>
      ) : (
        <AtiraEmpty description={t('permissions.members.empty')} />
      )}

      <WarningModal
        isOpen={removeMemberModalVisible}
        title={t('premission.member.remove.modal_title', {
          name: currentMember?.name,
        })}
        description={t('premission.member.remove.modal_description')}
        onConfirm={onRemoveMember}
        onClose={() => setRemoveMemberModalVisible(false)}
        loading={removeMemberLoading}
        confirmButtonText={t('common.remove')}
        denyButtonText={t('common.cancel')}
      />
    </Container>
  );
};
