import { t } from 'i18next';
import { RouteObject } from 'react-router-dom';

import { PermissionAction } from '../../model/permissions/types/PermissionAction';
import { PermissionType } from '../../model/permissions/types/PermissionType';
import { AtiraRoute } from '../AtiraRoute';
import { AtiraRouteAdmin } from '../AtiraRouteAdmin';
import { Layout } from '../Layout';
import { AdminRouteWithLazy } from '../admin/AdminRouteWithLazy';
import { AdminSearchUsersRouteWithLazy } from '../admin/AdminSearchRouteWithLazy';
import { DashboardRouteWithLazy } from '../dashboard/DashboardRouteWithLazy';
import { EntriesRouteWithLazy } from '../entries/EntriesRouteWithLazy';
import { EntryReadDrawer } from '../entries/EntryReadDrawer';
import { FormCreateEditRouteWithLazy } from '../form/FormCreateEditRouteWithLazy';
import { UserFormsRouteWithLazy } from '../form/UserFormsRouteWithLazy';
import { KnabanRouteWithLazy } from '../kanban/KanbanRouteWithLazy';
import { PermissionsRoute } from '../permissions/PermissionsRoute';
import { ProductsRoute } from '../product/ProductsRoute';
import { ProfileEdit } from '../profile/ProfileEdit';
import { Reports } from '../reports/Reports';
import { Subscription } from '../subscription/Subscription';
import { TaskRouteWithLazy } from '../tasks/TaskRouteWithLazy';

export const PrivateRouter: RouteObject[] = [
  {
    element: <Layout />,
    errorElement: <div>{t('common.error.back_home')}</div>,
    children: [
      {
        path: '/dashboard',
        element: <AtiraRoute component={<DashboardRouteWithLazy />} />,
      },
      {
        path: '/forms',
        element: (
          <AtiraRoute
            component={<UserFormsRouteWithLazy />}
            withPermissions={[
              {
                type: PermissionType.FORM,
                action: PermissionAction.READ,
              },
            ]}
          />
        ),
      },
      {
        path: '/entries',
        element: (
          <AtiraRoute
            component={<EntriesRouteWithLazy />}
            withPermissions={[
              {
                type: PermissionType.ENTRY,
                action: PermissionAction.READ,
              },
            ]}
          />
        ),
      },
      {
        path: '/entries/view/:id',
        element: (
          <AtiraRoute
            component={<EntryReadDrawer />}
            withPermissions={[
              {
                type: PermissionType.ENTRY,
                action: PermissionAction.READ,
              },
            ]}
          />
        ),
      },
      {
        path: '/deals',
        element: (
          <AtiraRoute
            component={<KnabanRouteWithLazy />}
            withPermissions={[
              {
                type: PermissionType.KANBAN,
                action: PermissionAction.READ,
              },
            ]}
          />
        ),
      },
      {
        path: '/reports',
        element: <AtiraRoute component={<Reports />} />,
      },
      {
        path: '/tasks',
        element: (
          <AtiraRoute
            component={<TaskRouteWithLazy />}
            withPermissions={[
              {
                type: PermissionType.LEAD_TASK,
                action: PermissionAction.READ,
              },
              {
                type: PermissionType.KANBAN_TASK,
                action: PermissionAction.READ,
              },
              {
                type: PermissionType.CONTACT_TASK,
                action: PermissionAction.READ,
              },
            ]}
          />
        ),
      },
      {
        path: '/products',
        element: <AtiraRoute component={<ProductsRoute />} />,
      },
      {
        path: '/forms/create',
        element: (
          <AtiraRoute
            component={<FormCreateEditRouteWithLazy />}
            withPermissions={[
              {
                type: PermissionType.FORM,
                action: PermissionAction.CREATE,
              },
            ]}
          />
        ),
      },
      {
        path: '/forms/edit',
        element: (
          <AtiraRoute
            component={<FormCreateEditRouteWithLazy />}
            withPermissions={[
              {
                type: PermissionType.FORM,
                action: PermissionAction.UPDATE,
              },
            ]}
          />
        ),
      },
      {
        path: '/profile',
        element: <AtiraRoute component={<ProfileEdit />} />,
      },
      {
        path: '/subscription',
        element: <AtiraRoute component={<Subscription />} />,
      },
      {
        path: '/permissions',
        element: <AtiraRoute component={<PermissionsRoute />} />,
      },
      {
        path: '/admin',
        element: <AtiraRouteAdmin component={<AdminRouteWithLazy />} />,
      },
      {
        path: '/admin/users',
        element: (
          <AtiraRouteAdmin component={<AdminSearchUsersRouteWithLazy />} />
        ),
      },
      {
        path: '/',
        element: <AtiraRoute redirect="dashboard" />,
      },
    ],
  },
];
